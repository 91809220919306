import React from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Layout from "../components/Layout";
import Img from "../components/Img";
import MainPost from "../components/MainPost";
import SEO from "../components/Seo";
import Breadcrumb from "../components/Breadcrumb";

function PostTemplate({ location, data: { mdx: post } }) {
  if (!post) {
    return <p>No Post Found? This should be a 404</p>;
  }

  const blogPathsByLanguage = {
    en: "/en/blog",
    de: "/de/blog",
    da: "/da/blog",
    se: "/se/blogg",
    no: "/no/blogg",
    nl: "/nl/blog",
    "x-default": "/en/blog"
    // add fr & fi language
  };

  const breadCrumbLevels = {
    Home: "/",
    Blog: blogPathsByLanguage[post.frontmatter.language],
    [post.frontmatter.title]: post.frontmatter.slug
  };

  // Create alternateLangs array from the hreflang object
  const alternateLangs = Object.entries(post.frontmatter.hreflang).map(([lang, href]) => ({
    hreflang: lang === 'x_default' ? 'x-default' : lang,
    href: href.startsWith('/') ? href : `/${href}`
  }));

  return (
    <Layout location={location} alternateLangs={alternateLangs}>

      <React.Fragment>
        <SEO
          title={post.frontmatter.titletag}
          description={post.frontmatter.description}
          lang={post.frontmatter.language}
          alternateLangs={alternateLangs}
          image={post.frontmatter.image.name}
          canonical={location.pathname}
          datePublished={post.frontmatter.publishISO}
          dateModified={post.frontmatter.updateISO}
        />
        <MainPost>
          <Img src={post.frontmatter.image.relativePath} alt={post.frontmatter.title} />
          <Breadcrumb breadCrumbLevels={breadCrumbLevels} />
          <MDXRenderer
            timeToRead={post.timeToRead}
            tags={post.frontmatter.tags && post.frontmatter.tags.split(", ")}
            date={post.frontmatter.update}
          >
            {post.body}
          </MDXRenderer>
        </MainPost>
      </React.Fragment>

    </Layout>
  );
}

export default PostTemplate;

export const pageQuery = graphql`
  query($slug: String!, $language: String!) {
    site {
      siteMetadata {
        author
      }
    }
    mdx(fields: { slug: { eq: $slug } }, frontmatter: { language: { eq: $language } }) {
      fields {
        relativePath
      }
      fileAbsolutePath
      excerpt
      body
      timeToRead
      id
      frontmatter {
        title
        slug
        titletag
        language
        hreflang {
          ... on HreflangObject {
            x_default
            da
            de
            en
            fi
            fr
            nl
            no
            se
          }
        }
        description
        tags
        genre
        update(formatString: "DD. MMMM YYYY")
        updateISO: update(formatString: "YYYY-MM-DDTHH:mm:ssZ")
        publish(formatString: "DD. MMMM YYYY")
        publishISO: publish(formatString: "YYYY-MM-DDTHH:mm:ssZ")
        image {
          id
          relativePath
          name
        }
      }
    }
  }
`;